body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-bg-color {
  background-color: #1a9dfe;
}

.btn {
  cursor: pointer;
}

.btn:active {
  opacity: 0.8;
}
 

.primary-color{
  color: #009ad6;
}
 
.primary-bg{
  background-color: #009ad6;

}