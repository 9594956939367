
.cny-icons{
    width:1rem;
    display:flex;
    flex-direction: row;
    justify-content:center;
     height:0.6rem; 
    /* margin-left:0.2rem; */
    margin-bottom:0.1rem;
     
     border-radius:0.1rem;
     
    }
    .cny-font-ali {
        color:#198fce
    }
    .cny-font-wx {
        color:#139900
    }
    .cny-checked-way{
        border: 2px solid #198fce;
        background: #198fce;
        color:#fff;
    }
    .cny-nochecked-way {
        border: 1px solid #9f9f9f;
    }

    .cny-icons:active{
        opacity: 0.6;
    }