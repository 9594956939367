
.icons{
    width:1.5rem;
    display:flex;
    flex-direction: row;
    justify-content:center;
     height:0.6rem; 
    margin-left:0.2rem;
    margin-bottom:0.1rem;
     
     border-radius:0.1rem;
     
    }
    .font-ali {
        color:#1296db
    }
    .font-wx {
        color:#139900
    }
    .checked-way{
        border: 2px solid #f4813e;
        background: #f4813e;
        color:#fff;
    }
    .nochecked-way {
        border: 1px solid #9f9f9f;
    }

    .icons:active{
        opacity: 0.6;
    }